import gql from "graphql-tag";

export const GET_PROFILE_BANK = gql`
  query {
    bankAccounts {
      isSuccess
      message
      records {
        _id
        bank_name
        branch_name
        account_no
        account_name
        bank_img
        created_at
      }
    }
  }
`;

export const CREATE_PROFILE_BANK = gql`
  mutation(
    $account_name: String!
    $account_no: String!
    $bank_name: String!
    $branch_name: String
    $bank_img: String
  ) {
    bank_create(
      input: {
        account_name: $account_name
        account_no: $account_no
        bank_name: $bank_name
        branch_name: $branch_name
        bank_img: $bank_img
      }
    ) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_PROFILE_BANK = gql`
  mutation(
    $_id: ID
    $account_name: String!
    $account_no: String!
    $bank_name: String!
    $branch_name: String
    $bank_img: String
  ) {
    bank_update(
      input: {
        _id: $_id
        account_name: $account_name
        account_no: $account_no
        bank_name: $bank_name
        branch_name: $branch_name
        bank_img: $bank_img
      }
    ) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const DELETE_PROFILE_BANK = gql`
  mutation($_id: ID!) {
    bank_delete(_id: $_id) {
      isSuccess
      message
    }
  }
`;

export const CANCEL_LINE = gql`
  mutation {
    cancelLine {
      isSuccess
      message
    }
  }
`;

export const GET_CASH = gql`
  query {
    cash {
      baht
      rateAlipay
    }
  }
`;

export const GET_PROFILE = gql`
  query {
    profile {
      uid
      full_name
      userId
      username
      password
      photo_url
      phone
      phone2
      email
      line
      cartCount
      rateOrder
      verify_file
      line_token
      address {
        _id
        name
        phone
        province
        amphoe
        district
        postcode
        address
        default
      }
    }
  }
`;

export const GET_PROFILE_ADDRESS = gql`
  query {
    profile {
      address {
        _id
        name
        phone
        province
        amphoe
        district
        postcode
        address
        default
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation(
    $full_name: String
    $password: String
    $email: String
    $phone: String
    $line: String
    $verify_filename: String
  ) {
    editProfileUser(
      input: {
        full_name: $full_name
        password: $password
        email: $email
        phone: $phone
        line: $line
        verify_filename: $verify_filename
      }
    ) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const CREATE_PROFILE_ADDRESS = gql`
  mutation(
    $name: String!
    $phone: String!
    $address: String!
    $province: String
    $amphoe: String
    $district: String
    $postcode: String
    $default: Boolean
  ) {
    address_create(
      input: {
        name: $name
        phone: $phone
        address: $address
        province: $province
        amphoe: $amphoe
        district: $district
        postcode: $postcode
        default: $default
      }
    ) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_PROFILE_ADDRESS = gql`
  mutation(
    $_id: ID!
    $name: String!
    $phone: String!
    $address: String!
    $province: String
    $amphoe: String
    $district: String
    $postcode: String
    $default: Boolean
  ) {
    address_update(
      input: {
        _id: $_id
        name: $name
        phone: $phone
        address: $address
        province: $province
        amphoe: $amphoe
        district: $district
        postcode: $postcode
        default: $default
      }
    ) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const DELETE_PROFILE_ADDRESS = gql`
  mutation($_id: ID!) {
    address_delete(_id: $_id) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation($input: PasswordInput!) {
    changePassword(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;
